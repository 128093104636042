import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { HeroLayout } from '@primer/gatsby-theme-doctocat';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HeroLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      